import { cn } from '~/utils/css/css';

import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';

const maxWithVariants = cva('w-full', {
  variants: {
    size: {
      sm: 'max-w-[640px]',
      base: 'max-w-[768px]',
      md: 'max-w-6xl',
      lg: 'max-w-[1480px]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

interface MaxWidthProps extends VariantProps<typeof maxWithVariants> {
  children: ReactNode;
  className?: string;
}

export const MaxWidth = ({ children, className, size }: MaxWidthProps) => {
  return (
    <div className={'flex w-full justify-center'}>
      <div className={cn(maxWithVariants({ size }), className, 'space-y-4')}>{children}</div>
    </div>
  );
};
